import React from 'react';

import styles from './Offering.module.css';
import ImageWithPopUp from '../ImageWithPopUp';

const Offering = ({
  imgUrl,
  title,
  description,
  size,
}) => {
  return (
    <div className={styles.app__offering}>
      <div className={styles.app__offering_image}>
        <ImageWithPopUp
          src={imgUrl}
          alt={title}
          width={200}
          height={200}/>
      </div>
      <div className={styles.app__offering_text}>
        <div className={styles.app__offering_title}>{title}</div>
        <div className={styles.app__offering_description}>{description}</div>
      </div>
      {size !== null &&
            <div className={styles.app__offering_serves}>
              <p>
                Serves {size}
              </p>
            </div>}
    </div>
  );
};


export default Offering;
