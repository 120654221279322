import React from 'react';
import Link from 'next/link';

import SectionTitle from '../../components/SectionTitle';
import Location from '../../components/Location';

import style from './Locations.module.css';

const Locations = ({newLocations}) => {
  return (
    <div className={style.app__locations}>
      <div className={style.app__locations_title}>
        <div>
          <SectionTitle
            title={`Location`}
            subtitle={`Now Open`}/>
        </div>
      </div>
      <div className={style.app__locations_detail_wrapper}>
        {newLocations.map((item) =>
          <div
            className={style.app__location}
            key={item.PK}>
            <Location
              location={item}
            />
          </div>)}
      </div>
      <div className={style.app__locations_button_wrapper}>
        <Link href={`/locations`} className='custom__button'>
          View All Locations
        </Link>
      </div>
    </div>
  );
};

export default Locations;
