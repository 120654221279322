import React from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';

import styles from './Location.module.css';
import {format} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';

const Location = ({
  location,
}) => {
  const tz = location?.timezone;
  const currentLocalDatetime = utcToZonedTime(new Date(), tz);

  return (
    <div className={styles.app__location}>
      {location.locationImg &&
            <Image
              className={styles.app__location_image}
              src={location.locationImg}
              alt={location.title}
              width={283}
              height={165}
              objectFit={`cover`}
            />}
      <div>
        <div>
          <p className={styles.app__location_name}>{location.title}</p>
          <p className={styles.app__location_address}>
            {location.address}
          </p>
        </div>
        <div className={styles.app__location_bottom_wrapper}>
          <p>{location.phoneNum}</p>
          {location.openingDate &&
          format(currentLocalDatetime, 'yyyy-MM-dd') >= location.openingDate ?
              (<Link
                href={`/locations/${location.storeCode}`}
                passHref
                legacyBehavior>
                <button className='custom__purple-button'>Order Now</button>
              </Link>) :<p>COMING SOON</p>
          }
        </div>
      </div>
    </div>
  );
};

export default Location;
