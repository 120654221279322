import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

import styles from './imageSlider.module.css';
import {useCallback, useEffect, useState} from 'react';
import SliderNavigation from './SliderNavigation';
import useWindowSize from '../../hooks/useWindowSize';
import ImageWithPopUp from '../ImageWithPopUp';

const ImageSlider = ({images, imageHeight, showingNum, autoPlayDelay}) => {
  const options = {delay: autoPlayDelay, stopOnInteraction: false}; // Options
  const autoplayRoot = (emblaRoot) => emblaRoot.parentElement; // Root node
  // eslint-disable-next-line new-cap
  const autoplay = Autoplay(options, autoplayRoot);
  const {width} = useWindowSize();
  const imageWidth = width !== undefined ? Math.round(width / showingNum) : 0;

  const [viewportRef, embla] = useEmblaCarousel(
      {
        loop: true,
        containScroll: 'keepSnaps',
        dragFree: true,
      }, [autoplay]);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);


  const scrollPrev = useCallback(() =>
    embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() =>
    embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  return (
    <div className={styles.embla} ref={embla}>
      <SliderNavigation
        onClickPrev={scrollPrev}
        prevBtnEnabled={prevBtnEnabled}
        onClickNext={scrollNext}
        nextBtnEnabled={nextBtnEnabled}
        selectedIndex={selectedIndex}
        totalSlides={images.length}/>

      <div className={styles.embla__viewport} ref={viewportRef}>
        <div className={styles.embla__container}>
          {images.map((image, index) => (
            <div
              key={index}
              className={styles.embla__slide}>
              <ImageWithPopUp
                src={image}
                height={imageHeight}
                width={imageWidth}
                objectFit={`cover`}
                alt={`slider image`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
