import React from 'react';
import ImageSlider from '../../components/ImageSlider';
import SectionTitle from '../../components/SectionTitle';

import styles from './Gallery.module.css';

const Gallery = ({images}) => (
  <div className={styles.app__gallery_container}>
    <div className={styles.app__gallery_wrapper}>
      <div className={styles.app__gallery_title_container}>
        <SectionTitle
          title={`Gallery`}
          subtitle={`Photos`} />
      </div>

      <ImageSlider
        images={images}
        imageHeight={400}
        showingNum={3}
        autoPlayDelay={10000}/>
    </div>
  </div>
);

export default Gallery;
