import React from 'react';
import Link from 'next/link';

import Offering from '../../components/Offering';
import SectionTitle from '../../components/SectionTitle';

import styles from './Featured.module.css';

const Featured = ({items, menuUrl}) => {
  return (
    <div className={styles.app__featured_wrapper}>
      <div className={styles.app__featured_title}>
        <SectionTitle
          title='Menu'
          subtitle={`Our Favorites`}/>
      </div>
      {menuUrl &&
            <div className={styles.app__featured_button_wrapper}>
              <Link href={menuUrl} legacyBehavior>
                <button className='custom__button'>View Full Menu</button>
              </Link>
            </div>}
      <div className={styles.app__featured_offerings_wrapper}>
        {items.map((item) =>
          <Offering
            className={styles.app__featured_offering}
            key={item.itemId}
            imgUrl={item.img}
            title={item.itemName}
            description={item.description}
            size={item.servingNum}
          />)}
      </div>
    </div>
  );
};

export default Featured;
