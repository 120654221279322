import Image from 'next/legacy/image';
import {useContext} from 'react';

import baseContext from '../../store/base_context';
import styles from './imagePopUp.module.css';

const ImagePopUp = ({src}) => {
  const baseCtx = useContext(baseContext);

  return (
    <>
      <div
        id={`overlay_image_wrapper`}
        className={styles.image_wrapper}
        onClick={() => baseCtx.updateModal(null)}>
        <Image
          src={src}
          layout={`fill`}
          objectFit={`contain`}
        />
      </div>
    </>
  );
};

export default ImagePopUp;
