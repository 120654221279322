import React, {useContext} from 'react';

import styles from './Header.module.css';
import locationContext from '../../store/location_context';
import baseContext from '../../store/base_context';
import {useRouter} from 'next/router';
import LocationSelector from '../../components/LocationSelector';

const Header = () => {
  const locationCtx = useContext(locationContext);
  const baseCtx = useContext(baseContext);
  const router = useRouter();

  const hasReservation =
      !locationCtx.location || locationCtx.location.reserveUrl;

  /**
   * Order and reserve button handler
   * @param {string} field
   */
  function buttonClickHandler(field) {
    if (locationCtx.location) {
      if (field === 'order') {
        // eslint-disable-next-line max-len
        router.push(`/locations/${locationCtx.location.storeCode}#location_info`);
      } else {
        router.push(locationCtx.location[field]);
      }
    } else {
      baseCtx.updateModal(<LocationSelector />);
    }
  }

  return (
    <>
      <div className={styles.app__header}>
        <video
          autoPlay={true}
          muted={true}
          loop={true}
          playsInline={true}
          className={styles.app__header_video_wrapper}>
          <source src={'https://tools.shakingcrab.com/static/ShakeTogether.mp4'} type={`video/mp4`}/>
        </video>
        <div className={styles.app__header_content}>
          <div className={styles.app__header_title}>
                    Bibs Up, Phones Down.<br />It&quot;s Shaking Time
          </div>
          <div className={styles.app__header_subtitle}>
                    Cajun Seafood Boil Experience
          </div>
          <div className={styles.app__header_location_btn_wrapper}>
            <button
              className='custom__location_btn'
              onClick={() => {
                baseCtx.updateModal(<LocationSelector />);
              }}>
              <i className='icon-sc-location' />
              <strong>
                {locationCtx.location ?
                locationCtx.location.title :
                  'Set Your Location'}
              </strong>
            </button>
          </div>
          <div className={`${styles.app__header_order_btn_wrapper} 
          ${hasReservation ? '': styles.flex_center}`}>
            {hasReservation &&
                <button
                  className='custom__icon-outline-button'
                  onClick={buttonClickHandler.bind(0, 'reserveUrl')}>
                  <i className={`${styles.app__header_icon} icon-sc-reserve` }/>
                  <span>
                Reservations
                  </span>
                </button>}
            <button
              className='custom__icon-outline-button'
              onClick={buttonClickHandler.bind(0, 'order')}>
              <i className={`${styles.app__header_icon} icon-sc-order` }/>
              <span>
             Order Now
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
