import Featured from './Featured';
import Chef from './Chef';
import FindUs from './FindUs';
import Footer from './Footer';
import Gallery from './Gallery';
import Header from './Header';
import Intro from './Intro';
import Laurels from './Laurels';
import Locations from './Locations';

export {
  Featured,
  Chef,
  FindUs,
  Footer,
  Gallery,
  Header,
  Intro,
  Laurels,
  Locations,
};
