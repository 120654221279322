import Image from 'next/legacy/image';
import {useContext} from 'react';

import baseContext from '../../store/base_context';
import ImagePopUp from '../ImagePopUp';

const ImageWithPopUp = ({
  src,
  alt,
  width,
  height,
  layout,
  objectFit,
}) => {
  const baseCtx = useContext(baseContext);

  return (
    <Image
      className={`custom__clickable_link`}
      src={src}
      alt={alt}
      width={width}
      height={height}
      layout={layout}
      objectFit={objectFit}
      onClick={() => baseCtx.updateModal(<ImagePopUp src={src} />)}
    />
  );
};

export default ImageWithPopUp;
