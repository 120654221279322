import styles from './sideNavigation.module.css';

const SliderNavigation = ({
  onClickPrev,
  onClickNext,
  selectedIndex,
  totalSlides,
}) => (
  <div className={styles.navigation_container}>
    <i
      className={`icon-sc-expand-left custom__clickable_link`}
      onClick={onClickPrev}>
      <span className={`path1 ${styles.navigation_icon_path1}`}/>
      <span className={`path2 ${styles.navigation_icon_path2}`}/>
    </i>
    <span className={styles.navigation_text}>
      {(selectedIndex + 1).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}
        /
      {totalSlides.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}
    </span>
    <i
      className={`icon-sc-expand-right custom__clickable_link`}
      onClick={onClickNext}>
      <span className={`path1 ${styles.navigation_icon_path1}`}/>
      <span className={`path2 ${styles.navigation_icon_path2}`}/>
    </i>
  </div>
);

export default SliderNavigation;
